import React, {useContext} from "react";
import {useAuth, useTextDomainContext, useUser} from "app/providers";
import {useMe} from "hooks";
import {Navigate, Outlet} from "react-router";
import {Button, Typography} from "@mui/material";
import {Box} from "@mui/system";

// import { LayoutSplashScreen } from "_metronic/layout";

/**
 *
 */
export function AcquireLogin() {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  useMe();
  const {isAuthenticated} = useAuth();

  // const navigate = useNavigate();
  // const { pathname } = useLocation();
  const {user} = useUser();
  // const user = null;

  // const { isRegistered, teams } = user || {
  // };

  // useEffect(() => {
  //   if (isRegistered !== undefined) {
  //     if (!isRegistered || !teams.length) {
  //       navigate("/registration-flow");
  //     } else navigate(pathname);
  //   }
  // }, [isRegistered, navigate, pathname, teams]);

  return isAuthenticated ? (
    user ? (
      <Outlet/>
    ) : (<>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        textAlign="center"
      >
        <Box>
          <Typography variant="h6" gutterBottom>
            {gettext("Please wait while we are verifying your credentials.")}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.replace(window.location.href)}
            sx={{
              mb: 2
            }}
          >
            {gettext("Retry")}
          </Button>

          <Typography variant="body1" gutterBottom>
            {gettext("or")}
          </Typography>

          <Button variant="outlined" color="secondary" href="/logout">
            {gettext("Login again")}
          </Button>
        </Box>
      </Box>
      {/* <LayoutSplashScreen /> */}
    </>
    )
  ) : (
    <>
      <Navigate to="/auth/login"/>
    </>
  );
}
